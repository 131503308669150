<template>
    <div class="hstack min-h-unset mt-2" v-if="!hideHeader">
        <h6 class="mb-1 me-2">{{$t('Properties')}}</h6>
        <small class="text-truncate">{{$t('Select property to add column in grid')}}</small>
    </div>
    <ODataGrid :data="properties" hideMenu noFooter hideActionColumn hideSystemColumn hideMultiselectColumn 
        hideGridMenu disableNavigation :rowclickhandler="handleRowClick" :style="listStyle"
        rowHeight="28">
        <OColumn colId="o365_PropertiesSelect" width="24" class="custom-cell" disableMenu disableResize>
            <template #headertext></template>
            <!--
            <template #filter>
                <input type="checkbox" class="form-check-input p-1 mx-auto">
            </template>
            -->
   
            <template #default="{row}">
                <input type="checkbox" class="form-check-input p-1" v-model="row.isSelected">
            </template>
        </OColumn>
        <OColumn colId="o365_PropertiesCaptopm" field="Caption" :headerName="$t('Caption')" flexWidth="49" width="200" class="border-none" :cellTitle="row => row.Caption"/>       
        <OColumn colId="o365_PropertiesName" field="Name" :headerName="$t('Name')" width="200" flexWidth="49" class="border-none" :cellTitle="row => row.Name"/>
        <OColumn colId="o365_PropertiesDataType" field="DataType" :headerName="$t('Data Type')" flexWidth="2" width="100" class="border-none" :cellTitle="row => row.DataType"/>
    </ODataGrid>
</template>

<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
// import type DataGridControl from 'o365.controls.DataGrid.ts';

import { getSelectedProperties } from './DataObject.PropertiesData.ts';

import { ref } from 'vue';

const props = defineProps<{
    dataObject: DataObject;
    hideHeader?: boolean;
    listStyle?: string
}>();

const properties = ref([]);

function handleRowClick(row) {
    row.isSelected = !row.isSelected;
}
async function init() {
    properties.value = await getSelectedProperties(props.dataObject);
}
init();

</script>
